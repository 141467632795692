<template>
	<div class="row">
		<div :class="[admin ? ['col-xxl-7', 'order-5', 'order-xxl-5'] : ['col-lg-12', 'col-xxl-12', 'order-5', 'order-xxl-5']]">
			<AdminAccountList />
		</div>

		<div v-if="admin" class="col-xxl-5 order-5 order-xxl-5">
			<AdminSiteList />
		</div>

		<div v-if="admin" class="col-lg-12 col-xxl-12 order-5 order-xxl-5">
			<DeviceList />
		</div>

		<div v-if="admin" class="col-lg-12 col-xxl-12 order-5 order-xxl-5">
			<ChangeLog />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { SET_DEVICE_TYPES } from '@/core/services/store/admin.module';

export default {
	data() {
		return {
			admin: false,
		};
	},
	components: {
		AdminAccountList: () => import('@/view/content/widgets/admin/AdminAccountList.vue'),
		AdminSiteList: () => import('@/view/content/widgets/admin/AdminSiteList.vue'),
		DeviceList: () => import('@/view/content/widgets/admin/DeviceList.vue'),
		ChangeLog: () => import('@/view/content/widgets/admin/ChangeLog.vue'),
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	mounted() {
		if (this.currentUser.role === 'user') this.$router.push({ name: 'dash' });
		if (this.currentUser.role === 'admin' || this.currentUser.role === 'noc' || this.currentUser.role === 'staff') {
			this.admin = true;
		}
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin' }]);
		this.$http.get('devicetypes').then(res => {
			this.$store.commit(SET_DEVICE_TYPES, res.data.data);
		});
	},
};
</script>

<style></style>
